<template>
  <div class="app-layout" :class="`is-${String(route.name) === 'mobile' ? 'page-mobile' : String(route.name)}`">
    <app-header />
    <div class="app-layout__main">
      <slot />
    </div>
    <app-footer />
    <app-toast />
    <site-icons />
  </div>
  <template v-if="overlaySupport">
    <button class="support-overlay" @click.prevent="isOverlaySupportActive = true" />
    <div class="support-overlay-popup">
      <ui-notification-popup v-model="isOverlaySupportActive">
        <div class="content">
          <h3 class="is-title is-size-3">We’re offline right now.</h3>
          <p>
            We’re here between 10AM and 6PM, Monday to Saturday. Why not email us right
            now at
            <a href="mailto:care@zeronet.co.nz">care@zeronet.co.nz</a>
          </p>
        </div>
        <template #actions>
          <ui-button @click="isOverlaySupportActive = false">Ok</ui-button>
        </template>
      </ui-notification-popup>
    </div>
  </template>
</template>

<script lang="ts" setup>
import { useRegistration, useRoute, watch } from "#imports";
import AppHeader from "@/components/global/layout/AppHeader.vue";
import AppFooter from "@/components/global/layout/AppFooter.vue";
import SiteIcons from "@/components/global/elements/SiteIcons.vue";
import AppToast from "~/components/global/layout/AppToast.vue";
import { useMemberSignup } from "~/stores/signup";
import UiButton from "~/components/global/ui/UiButton.vue";
import UiNotificationPopup from "~/components/global/ui/UiNotificationPopup.vue";

const route = useRoute();
const { isRegistrationFunnel } = useRegistration();
const signup = useMemberSignup();
const { isWithinBusinessHours } = useSupport();

watch(
  route,
  () => {
    // do not change anything in the following routes
    const ignoreRoutes = ["mobile", "login", "authorize"];
    if (
      isRegistrationFunnel.value ||
      ignoreRoutes.includes(route.name as string) ||
      route.path.startsWith("/account")
    ) {
      return;
    }

    switch (route.meta.funnel) {
      case "5 month":
        signup.setOfferHalfPrice(5);
        break;
      case "6 month":
        signup.setOfferHalfPrice(6);
        break;
      default:
        signup.setOfferHalfPrice();
        break;
    }
  },
  {
    immediate: true,
  }
);

const overlaySupport = ref(false);
const isOverlaySupportActive = ref(false);

onMounted(() => {
  overlaySupport.value = !isWithinBusinessHours();
});
</script>

<style lang="scss" scoped>
.app-layout {
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  min-height: 100vh;

  &__main {
    position: relative;
    overflow: hidden;
    min-height: rem(400);
  }
}

.support-overlay {
  position: fixed;
  background: red;
  width: 64px;
  height: 64px;
  right: 10px;
  bottom: 20px;
  z-index: 99999;
  opacity: 0;
  cursor: pointer;
}

.support-overlay-popup {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
</style>
